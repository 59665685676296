import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';
import { ChatStatusMapping } from '../../../services/apiService/definitions/constants';
import { serverErrorIcon } from '../../../utils/utils';
import StopChatIcon from '../../Core/ChatInput/StopIcon';
import OfflineIcon from '../../Core/OfflineIcon/OfflineIcon';
import Retry from '../../Core/Retry';

import styles from './MessageErrorState.module.css';

export const MessageErrorState: React.FC<{
  answerNodeId: string;
  isFailedStatus?: boolean;
  isNetworkError?: boolean;
  isChatChatGenerationStopped?: boolean;
  isServerUnavailableError?: boolean;
  isAbandonedAnswerNodeError?: boolean;
  genericErrorMessage?: string;
  nerValues: string[];
}> = ({
  answerNodeId,
  isFailedStatus,
  isNetworkError,
  isChatChatGenerationStopped,
  isServerUnavailableError,
  isAbandonedAnswerNodeError,
  genericErrorMessage,
  nerValues,
}) => {
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const answerNode = conversationTree?.nodes[answerNodeId];
  const userEmail = useSelector((state: RootState) => state.user.userData?.email)?.toLowerCase();
  const isChatOwner =
    conversationTree && conversationTree.nodes[answerNodeId].data.user_email.toLowerCase() === userEmail;
  const renderError = () => {
    if (isChatChatGenerationStopped) {
      return (
        <div className={`${styles.chatChatGenerationStoppedErrorText}`}>
          <div className={`${styles.chatStoppedText}`}>
            <StopChatIcon />
            <div>Response stopped! Want to ask a different question or retry?</div>
          </div>
          {isChatOwner && <Retry answerNodeId={answerNodeId} />}
        </div>
      );
    } else if (isFailedStatus) {
      const subStatus = (answerNode?.data.sub_status || []).map((status) => ChatStatusMapping[status]).filter(Boolean);
      return (
        <p className={styles.network_error_text}>
          {subStatus[subStatus.length - 1] || 'Something went wrong. Please try again in sometime.'}
          {isChatOwner && <Retry answerNodeId={answerNodeId} />}
        </p>
      );
    } else if (isNetworkError) {
      return (
        <p className={styles.network_error_text}>
          <OfflineIcon />
          Unable to connect to the internet. Please check your connection and try again.
        </p>
      );
    } else if (isServerUnavailableError) {
      return (
        <div className={styles.network_error_text}>
          <div className={`${styles.serverUnavailableErrorText}`}>
            {serverErrorIcon}
            <div>Server is currently unavailable. Please try again in few minutes.</div>
          </div>
          {isChatOwner && <Retry answerNodeId={answerNodeId} />}
        </div>
      );
    } else if (isAbandonedAnswerNodeError) {
      return (
        <p className={styles.network_error_text}>
          {serverErrorIcon}
          <div>The request has timed out due to some error. Please try again in some time.</div>
          {isChatOwner && <Retry answerNodeId={answerNodeId} />}
        </p>
      );
    } else {
      return (
        <p className={styles.network_error_text}>
          {genericErrorMessage || 'Something went wrong. Please try again in sometime.'}
          {isChatOwner && <Retry answerNodeId={answerNodeId} />}
        </p>
      );
    }
  };

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorMessageContainer}>{renderError()}</div>
      {nerValues.length > 0 && (
        <div className={`${styles.nerContainer}`}>
          <div className="error-item" style={{ width: '100%', display: 'flex', gap: 'var(--Paragraph-18)' }}>
            <p style={{ fontSize: 'var(--Info-14)', fontWeight: '600', color: '#B1B1B1', flex: '0 0 115px' }}>
              Analysing the Question
            </p>
            <div style={{ flex: '1', flexWrap: 'wrap', display: 'flex', gap: '8px' }}>
              {nerValues.map((item: any, index: number) => (
                <p className={`${styles.nerPill}`} key={index}>
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
