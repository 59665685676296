import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  continueConversation,
  fetchAndLoadSharedConversation,
  setIsReceipient,
} from '../../../redux/features/conversationSlice';
import { fetchChatHistory } from '../../../redux/features/historySlice';
import { fetchDiscoverInsights, fetchFAQQuestions, fetchKnowYourData } from '../../../redux/features/landingPageSlice';
import { setProjectKey } from '../../../redux/features/sideBar';
import { setRegistered, setSharedProjectKey } from '../../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  ApiResponse,
  ContinueConversationResponse,
  SharedConversationTree,
} from '../../../services/apiService/definitions/types';
import Unregistered from '../../Unregistered/Unregistered';
import ChatLayout from '../ChatLayout/ChatLayout';

import styles from './ShareConversationLayout.module.css';
const ShareConversationsLayout = () => {
  const { trace_id } = useParams<{ trace_id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const receipient = useSelector((state: RootState) => state.conversation.isReceipient);
  const conversations = useSelector((state: RootState) => state.history.conversations);
  const navigate = useNavigate();
  const isTermsAccepted = localStorage.getItem('termsAccepted') === 'true';
  const onboardingComplete = localStorage.getItem('showOnboarding') === 'complete';

  const handleNavigation = (registered: boolean) => {
    if (conversations && conversations.length > 0 && isTermsAccepted) {
      navigate(`${window.location.pathname}${window.location.search}`);
    } else if (isTermsAccepted && onboardingComplete) {
      navigate(`${window.location.pathname}${window.location.search}`);
    } else if (registered) {
      navigate('/onboarding', { state: { from: window.location.pathname } });
    } else {
      navigate(`/chat/share/${trace_id}`);
    }
  };

  useEffect(() => {
    dispatch(setIsReceipient(true));
  }, []);

  useEffect(() => {
    if (trace_id && receipient) {
      dispatch(fetchAndLoadSharedConversation({ traceId: trace_id })).then((result) => {
        if (result.type.endsWith('fulfilled')) {
          const apiRespone = result.payload as ApiResponse<SharedConversationTree>;
          const { registered, project_key } = apiRespone.data;
          dispatch(setRegistered(registered));
          dispatch(setSharedProjectKey(project_key));
          if (registered) {
            dispatch(setProjectKey(project_key));
            dispatch(fetchChatHistory({ projectKey: project_key }));
            dispatch(fetchFAQQuestions({ projectKey: project_key }));
            dispatch(fetchDiscoverInsights({ projectKey: project_key }));
            dispatch(fetchKnowYourData({ projectKey: project_key }));
          }
          handleNavigation(registered);
        }
      });
    }
  }, [dispatch, trace_id, receipient, isTermsAccepted, onboardingComplete, navigate]);

  const cloneConversation = () => {
    if (trace_id) {
      dispatch(continueConversation(trace_id)).then((result) => {
        if (result.type.endsWith('fulfilled')) {
          const apiRespone = result.payload as ApiResponse<ContinueConversationResponse>;
          navigate('/chat/' + apiRespone.data.conversation_id);
          dispatch(setIsReceipient(false));
        }
      });
    }
  };

  return (
    <ChatLayout
      registredUserBottomContent={
        <div className={styles.shared_conversation} onClick={cloneConversation}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path
              d="M9.49693 5.52795C7.79984 5.17218 6.47336 3.84571 6.11765 2.14859C6.07395 1.94026 5.8902 1.79102 5.67728 1.79102C5.46435 1.79102 5.2806 1.94026 5.23693 2.14862C4.88119 3.84571 3.55472 5.17215 1.85763 5.52787C1.64927 5.57154 1.5 5.75531 1.5 5.96821C1.5 6.18111 1.64924 6.36489 1.85763 6.40856C3.55469 6.76432 4.88111 8.09077 5.23685 9.78786C5.28052 9.99622 5.46427 10.1455 5.6772 10.1455C5.89009 10.1455 6.07387 9.99625 6.11754 9.78786C6.47331 8.09077 7.79981 6.76432 9.4969 6.40864C9.70526 6.36497 9.85453 6.18122 9.85453 5.96829C9.8545 5.75539 9.70526 5.57162 9.49693 5.52795Z"
              fill="#07080B"
            />
            <path
              d="M12.767 9.74084C11.9674 9.57325 11.3424 8.94829 11.1748 8.14864C11.1311 7.94029 10.9474 7.79102 10.7344 7.79102C10.5215 7.79102 10.3378 7.94023 10.2941 8.14862C10.1264 8.94823 9.50145 9.5732 8.70187 9.74082C8.49351 9.78448 8.34424 9.96826 8.34424 10.1812C8.34424 10.3941 8.49348 10.5778 8.70187 10.6215C9.50143 10.7891 10.1264 11.4141 10.294 12.2137C10.3377 12.4221 10.5215 12.5714 10.7344 12.5714C10.9473 12.5714 11.1311 12.4221 11.1747 12.2137C11.3424 11.4141 11.9674 10.7891 12.767 10.6215C12.9753 10.5779 13.1246 10.3941 13.1246 10.1812C13.1246 9.96829 12.9754 9.78454 12.767 9.74084Z"
              fill="#07080B"
            />
          </svg>
          Continue Conversation
        </div>
      }
      unRegistredUserContent={<Unregistered />}
    />
  );
};

export default ShareConversationsLayout;
