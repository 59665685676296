import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import useAnalytics from '../../../hooks/useAnalytics';
import useSignalR from '../../../hooks/useSignalR';
import {
  mergeViewSourceState,
  resetConversationState,
  setIsReceipient,
  updateConversationTreeFromSignalREvent,
} from '../../../redux/features/conversationSlice';
import { fetchChatHistory } from '../../../redux/features/historySlice';
import { setIsCollapsed, setisCollapsedState } from '../../../redux/features/sideBar';
import { setRegistered } from '../../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { SignalREventData } from '../../../services/apiService/definitions/types';
import ChatInput from '../../Core/ChatInput/ChatInput';
import Disclaimer from '../../Core/Disclaimer/Disclaimer';
import ChatHistoryContainer from '../../Core/History/ChatHistoryContainer';
import ScrollToBottomButton from '../../Core/ScrollToBottom/ScrollToBottom';
import { ErrorBoundary } from '../../ErrorBoundary';
import Header from '../../Header/Header';
import NewChatButton from '../../Home/NewChat/NewChatButton';
import Search from '../../Home/Search/Search';
import InfoContainer from '../../Info/Info';
import LogoComponent from '../../LandingScreen/IC-Logo';
import ViewSourceReasoning from '../../ViewSourceReasoning';

import styles from './ChatLayout.module.css';
type Props = {
  registredUserBottomContent?: React.ReactNode;
  unRegistredUserContent?: React.ReactNode;
};

const ChatLayout: React.FC<Props> = React.memo(({ registredUserBottomContent, unRegistredUserContent }) => {
  const { conversation_id } = useParams<{ conversation_id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { connection } = useSignalR({});
  const analytics = useAnalytics();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const theme = useSelector((state: RootState) => state.user.theme);
  const selectedProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const isCollapsed = useSelector((state: RootState) => state.sideBar.isCollapsed);
  const isConversationLoading = useSelector((state: RootState) => state.conversation.isConversationLoading);
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const viewSourceState = useSelector((state: RootState) => state.conversation.viewSourceState);
  const userEmail = useSelector((state: RootState) => state.user.userData?.email);
  const registered = useSelector((state: RootState) => state.user.registered);
  const conversations = useSelector((state: RootState) => state.history.conversations);
  const chatInputRef = useRef<HTMLTextAreaElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const receipient = useSelector((state: RootState) => state.conversation.isReceipient);
  const navigate = useNavigate();
  const handleCollapseClick = useCallback(() => {
    dispatch(setIsCollapsed());
    dispatch(mergeViewSourceState({ show: false }));
  }, [dispatch]);

  const handleCollapseClicksearch = useCallback(() => {
    dispatch(setIsCollapsed());
    setTimeout(() => {
      isCollapsed && searchInputRef.current?.focus();
    }, 0);
  }, [dispatch, isCollapsed]);

  const scrollToBottom = useCallback(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTo({
        top: chatAreaRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  const processSignalREvent = useCallback(
    async (event: SignalREventData) => {
      const { chat_id, task_status, final_response, conversation_name, status } = event;
      const taskStatus = task_status?.trim();
      dispatch(updateConversationTreeFromSignalREvent(event));
      if (taskStatus === 'FAILURE') {
        analytics.trackChatResponseReceived({
          success: false,
          chart_present: false,
          source: '',
          conversation_id: conversationId,
          question_type: '',
          chat_id: chat_id,
          question_status: 'FAILURE',
          status: 'FAILURE',
        });
      } else if (final_response?.response.length) {
        analytics.trackChatResponseReceived({
          success: !final_response.error_flag,
          chart_present: !!final_response.chart_results,
          source: final_response.answer_source,
          conversation_id: conversationId,
          question_type: final_response.response && final_response.response.length > 1 ? 'complex' : 'simple',
          chat_id: chat_id,
          question_status: status,
          status: 'COMPLETED',
        });
      }
      if (conversation_name) {
        // Refresh chat history to fetch name of the conversation
        dispatch(fetchChatHistory({ projectKey: selectedProjectKey }));
      }
    },
    [conversationId, dispatch, selectedProjectKey],
  );

  useEffect(() => {
    const channelName = userEmail;
    if (channelName) {
      connection?.on(channelName, processSignalREvent);
    }
    return () => {
      if (channelName) {
        connection?.off(channelName);
      }
    };
  }, [connection, userEmail, processSignalREvent]);

  useEffect(() => {
    if (conversation_id && !isConversationLoading && chatAreaRef.current) {
      scrollToBottom();
    }
  }, [conversation_id, isConversationLoading, scrollToBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatAreaRef.current) {
        const { scrollHeight, clientHeight, scrollTop } = chatAreaRef.current;
        const scrollToBottom = scrollHeight - clientHeight;
        setShowScrollButton(scrollTop < scrollToBottom);
        if (scrollToBottom - scrollTop < 100) {
          setShowScrollButton(false);
        }
      }
    };
    if (chatAreaRef.current) {
      chatAreaRef.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    return () => {
      if (chatAreaRef.current) {
        chatAreaRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (!receipient) {
      const isTermsAccepted = localStorage.getItem('termsAccepted') === 'true';
      if (conversations && conversations.length > 0 && isTermsAccepted) {
        navigate(`${window.location.pathname}${window.location.search}`);
      } else {
        const onboarding = localStorage.getItem('showOnboarding') === 'complete';
        if (isTermsAccepted && onboarding) {
          navigate(`${window.location.pathname}${window.location.search}`);
        } else {
          console.log('registered', registered);
          navigate('/onboarding', { state: { from: window.location.pathname } });
        }
      }
    }
  }, [receipient]);

  const handleUnregisteredClick = () => {
    navigate('/chat');
    dispatch(resetConversationState());
    dispatch(setIsReceipient(false));
    dispatch(setRegistered(true));
  };

  return (
    <>
      <div
        className={`${styles.container} ${theme === 'light-theme' ? 'light-theme' : ''}`}
        style={{ justifyContent: registered ? '' : 'center', flexDirection: !registered ? 'column' : 'row' }}
      >
        {!registered && (
          <div className={styles.unregistered_logo} onClick={handleUnregisteredClick}>
            {' '}
            <LogoComponent />
          </div>
        )}

        {registered && (
          <div
            className={`${styles.left_bar} ${isCollapsed ? styles.left_bar_collapsed : styles.left_bar_nocollapsed}`}
          >
            <div className={styles.left_bar_content}>
              <div className={isCollapsed ? styles.logo_container_collapse : styles.logo_container_nocollapse}>
                <div className={`${styles.logo} ${isCollapsed ? styles.log_collapse : styles.logno_collapse}`}>
                  <Link to="/">
                    <img
                      src={
                        isCollapsed
                          ? theme === 'light-theme'
                            ? `${process.env.PUBLIC_URL}/images/IC_landing_collapse_light.png`
                            : `${process.env.PUBLIC_URL}/images/IC_landing_collapse.png`
                          : theme === 'light-theme'
                            ? `${process.env.PUBLIC_URL}/images/IC_landing_logo_light.png`
                            : `${process.env.PUBLIC_URL}/images/IC_landing_logo.png`
                      }
                      className={isCollapsed ? styles.logoleft_bar_collapsed : styles.logoleft_bar_nocollapsed}
                      style={
                        isCollapsed
                          ? {
                              width: theme === 'light-theme' ? '31px' : '',
                              marginLeft: theme === 'light-theme' ? '' : '',
                            }
                          : {}
                      }
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className={styles.AbLogo}>
                  <img
                    src={
                      isCollapsed
                        ? ``
                        : theme === 'light-theme'
                          ? '/images/ABI_Logo_Light_Theme.png'
                          : '/images/ABInBev.png'
                    }
                  />
                </div>
              </div>
              <Search
                isCollapsed={isCollapsed}
                handleCollapseClicksearch={handleCollapseClicksearch}
                handleCollapseClick={handleCollapseClick}
                inputRef={searchInputRef}
              />
              <div className={styles.new_Chat_Button_container} id="component-d-element">
                <NewChatButton isCollapsed={isCollapsed} chatInputRef={chatInputRef} />
              </div>

              <div className={styles.history_container} id="component-c-element">
                {isCollapsed ? null : (
                  <div className={styles.history}>
                    <ChatHistoryContainer chatInputRef={chatInputRef} />
                  </div>
                )}
              </div>
            </div>
            <InfoContainer />
          </div>
        )}

        <div
          className={styles.content}
          style={{
            width: !registered ? '100%' : isCollapsed ? 'calc(100% - 72px)' : 'calc(100% - 222px)',
            overflow: !registered ? 'auto' : '',
            padding: !registered ? '0 14% var(--xs) 14%' : '',
          }}
        >
          {registered && (
            <div className={styles.header}>
              <Header />
            </div>
          )}
          <div className={styles.chatArea}>
            <div className={styles.chatAreaInner} ref={chatAreaRef}>
              <Outlet />
              <div className={styles.chatAreaInner__chatInput} ref={chatAreaRef}>
                {registredUserBottomContent && registered ? (
                  <>{registredUserBottomContent}</>
                ) : (
                  <>
                    {registered && (
                      <>
                        <ChatInput chatInputRef={chatInputRef} />
                        <Disclaimer />
                      </>
                    )}
                  </>
                )}

                <div className={styles.chatAreaInner__scrollToBottom}>
                  {showScrollButton && <ScrollToBottomButton scrollToBottom={scrollToBottom} />}
                </div>
              </div>
              {!registered && <>{unRegistredUserContent}</>}
            </div>
            <div className={styles.view_source_area}></div>
          </div>
        </div>

        {isCollapsed && viewSourceState?.show && (
          <div className={styles.view_source_area}>
            <ErrorBoundary>
              <ViewSourceReasoning
                onClose={() => {
                  dispatch(setisCollapsedState(false));
                  dispatch(mergeViewSourceState({ show: false }));
                }}
              />
            </ErrorBoundary>
          </div>
        )}
      </div>
    </>
  );
});

ChatLayout.displayName = 'ChatLayout';

export default ChatLayout;
