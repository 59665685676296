import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../../redux/store';
import ShareBox from '../../Share/ShareBox';

import styles from './ChartEntry.module.css';

type ChatEntryProps = {
  entry: {
    conversation_id: string;
    project_key: string;
    user_email: string;
    name: string;
    updated_at: string;
    trace_id: string | null;
  };
};

const ChatEntry: React.FC<ChatEntryProps> = ({ entry }) => {
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  return (
    <>
      <Link to={`${'/chat/' + entry.conversation_id}`} style={{ textDecoration: 'none' }}>
        <div className={styles.chat_history_list}>
          <div
            className={`${styles.chat_history_list_names}  ${entry.conversation_id === conversationId ? styles.chat_history_list_names_active : ''} `}
          >
            <p>
              {entry.trace_id ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    style={{ marginRight: '4px' }}
                  >
                    <path
                      d="M8.335 6.565C9.02 7.03 9.5 7.66 9.5 8.5V10H11.5V8.5C11.5 7.41 9.715 6.765 8.335 6.565Z"
                      fill="#F5E003"
                    />
                    <path
                      d="M7.5 6C8.605 6 9.5 5.105 9.5 4C9.5 2.895 8.605 2 7.5 2C7.265 2 7.045 2.05 6.835 2.12C7.25 2.635 7.5 3.29 7.5 4C7.5 4.71 7.25 5.365 6.835 5.88C7.045 5.95 7.265 6 7.5 6Z"
                      fill="#F5E003"
                    />
                    <path
                      d="M4.5 6C5.605 6 6.5 5.105 6.5 4C6.5 2.895 5.605 2 4.5 2C3.395 2 2.5 2.895 2.5 4C2.5 5.105 3.395 6 4.5 6ZM4.5 3C5.05 3 5.5 3.45 5.5 4C5.5 4.55 5.05 5 4.5 5C3.95 5 3.5 4.55 3.5 4C3.5 3.45 3.95 3 4.5 3Z"
                      fill="#F5E003"
                    />
                    <path
                      d="M4.5 6.5C3.165 6.5 0.5 7.17 0.5 8.5V10H8.5V8.5C8.5 7.17 5.835 6.5 4.5 6.5ZM7.5 9H1.5V8.505C1.6 8.145 3.15 7.5 4.5 7.5C5.85 7.5 7.4 8.145 7.5 8.5V9Z"
                      fill="#F5E003"
                    />
                  </svg>
                  {entry.name.replace(/_/g, ' ')}
                </>
              ) : (
                entry.name.replace(/_/g, ' ')
              )}
            </p>
            {
              <div className={styles.share_box}>
                <ShareBox position="right" />
              </div>
            }
          </div>
        </div>
      </Link>
    </>
  );
};

export default ChatEntry;
