// Toast.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideToast } from '../../redux/features/toastSlice';
import { AppDispatch, RootState } from '../../redux/store';

import ToastIcon from './ToastIcon';
import ToastSuccessIcon from './ToastSuccessIcon';

import './Toast.css';

const Toast: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { message, type, visible } = useSelector((state: RootState) => state.toast);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
      }, 5000); // Auto close after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [dispatch, visible]);

  if (!visible) return null;

  return (
    <div className={`toast-container ${type}`}>
      <div className="toast-message">
        <span className="icon">{type === 'success' ? <ToastSuccessIcon /> : <ToastIcon />}</span>
        {message}
      </div>
      <button className={`close-button ${type}`} onClick={() => dispatch(hideToast())}>
        ×
      </button>
    </div>
  );
};

export default Toast;
