import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSharedUserProfile, updateUserEmails } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { ApiResponse } from '../../../services/apiService/definitions/types';

type Props = {
  isChatOwner?: boolean;
  nodeEmail?: string;
};

const UserAvatar: React.FC<Props> = ({ isChatOwner, nodeEmail }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userEmails = useSelector((state: RootState) => state.conversation.userEmails);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const defaultImage = `${process.env.PUBLIC_URL}/images/user-image.png`;
  const userImage = useSelector((state: RootState) => state.user.userData?.image);
  useEffect(() => {
    if (!isChatOwner && nodeEmail) {
      const email = userEmails.find((email) => email.name === nodeEmail);
      const isEmptyBlob = email && email.image === '';
      if (!email?.image || isEmptyBlob) {
        dispatch(fetchSharedUserProfile({ userEmail: nodeEmail })).then((result) => {
          if (result.type.endsWith('fulfilled')) {
            const apiResponse = result.payload as ApiResponse<string>;
            if (apiResponse.data) {
              const imageBlob = new Blob([apiResponse.data], { type: 'image/png' });
              const url = URL.createObjectURL(imageBlob);
              setImageUrl(url);

              // Update the userEmails array immutably
              const updatedEmails = email
                ? userEmails.map((userEmail) =>
                    userEmail.name === nodeEmail ? { ...userEmail, image: url } : userEmail,
                  )
                : [...userEmails, { name: nodeEmail, image: url }];
              dispatch(updateUserEmails({ userEmails: updatedEmails }));
            }
          }
        });
      } else if (email?.image && !isEmptyBlob) {
        setImageUrl(email.image);
      }
    } else {
      setImageUrl(userImage ?? null);
    }
  }, [isChatOwner, nodeEmail]);
  return (
    <img
      src={imageUrl || defaultImage}
      alt="profile"
      className="profImg"
      loading="lazy"
      onError={(e) => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.src = defaultImage;
      }}
    />
  );
};

export default UserAvatar;
