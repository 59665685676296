import React from 'react';

import LogoComponent from '../LandingScreen/IC-Logo';

import styles from './Terms.module.css';
const TermsofUse = () => {
  return (
    <div
      className="onbording-container"
      style={{
        height: '100vh',
        display: 'flex',
        backgroundColor: 'var(--Greys-Base-BG-800)',
      }}
    >
      <div className="IC-Onboarding-container terms">
        <div className="IC-Onboarding-main terms" style={{ justifyContent: 'flex-start' }}>
          <div className="IC-Onboarding-notice-container logo" style={{ marginTop: '0', position: 'relative' }}>
            <LogoComponent
              style={{
                position: 'absolute',
                right: 0,
                left: 0,
                margin: 'auto',
                top: '-50px',
                width: '110px',
                height: '20px',
              }}
            />
          </div>
          <div className="IC-Onboarding-user-title terms">
            <p>Terms of use</p>
          </div>
          <div className="IC-Onboarding-terms" style={{ color: 'var(--Greys-Top-0)' }}>
            <ul style={{ listStyleType: 'auto', padding: '20px', lineHeight: '120%' }} className={styles.terms_items}>
              <li className={styles.Terms_tile}>
                <strong>Permitted use of the Tool</strong>
              </li>
              <ul style={{ listStyleType: 'disc', padding: '15px' }}>
                <li>
                  <strong className={styles.terms_list}>Only use the Tool for your work at AB InBev:</strong> You may
                  use the Tool only for tasks or responsibilities associated with your employment at or engagement by
                  ABI. Personal use of the Tool is not permitted
                </li>
                <li>
                  <strong className={styles.terms_list}>Do not share your Single Sign-On credentials:</strong> You may
                  not share your Single Sign-On credentials or otherwise enable any other person to use the Tool. All AB
                  InBev employees and secondees who have access to the Tool should access the Tool only using his or her
                  own Single Sign-On credentials.
                </li>
                <li>
                  <strong className={styles.terms_list}>Always follow the law and AB InBev’s Policies:</strong> Always
                  remember that, when using the Tool, you are in ABI’s environment. Hence, you are responsible for
                  ensuring the lawful and ethical use of AI systems, upholding the{' '}
                  <a
                    href="https://www.ab-inbev.com/content/dam/universaltemplate/abinbev/pdf/AB%20InBev%20InterimCOBC%20Final%202020_New%20Principles.pdf"
                    style={{ color: '#4ac9ee' }}
                  >
                    Code of Business Conduct
                  </a>{' '}
                  and the principles of these Terms of Use and complying with all applicable laws, regulations, internal
                  policies and contractual obligations. Seek further advice by contacting your Ethics & Compliance team
                  directly or through the{' '}
                  <a
                    href="https://compliancechannelglobal.ab-inbev.com/authentication?returnUrl=%2F&queryParams=%7B%22%22:%22%22%7D"
                    style={{ color: '#4ac9ee' }}
                  >
                    Compliance Channel.
                  </a>{' '}
                </li>
                <li>
                  <strong className={styles.terms_list}>Protect privacy and confidentiality:</strong> The information
                  you are in contact with in the context of your work for AB InBev might be confidential, commercially
                  sensitive or include personal data. While this is an internally approved tool, please ensure that the
                  individuals who have access to your queries and the generated outputs are limited to those with the
                  appropriate authorizations or need to know. Hence, please refrain from inputting information that is
                  not necessary for the execution of the task, including, without prior Legal approval, any personal
                  data (e.g., data that can identify individuals), trade secrets or other non-public, proprietary or
                  confidential information.{' '}
                </li>
                <li>
                  <strong className={styles.terms_list}>
                    Don’t consider or hold out the output generated by Insights Copilot as the position of AB InBev:
                  </strong>{' '}
                  While Insights Copilot is a secure and private deployment for insights generation related use cases.
                  Infrastructure related to Insights Copilot is hosted on ABInBev’ s Microsoft Azure Cloud
                  infrastructure, it generates insights using Enterprise data sources (like data lake or other company
                  data assets) and sometimes also based on trusted public Internet sources. As such, no output generated
                  by Insights Copilot or any of its modules should be considered the position of ABI. You may not make
                  any statement providing or suggesting that Insights Copilot outputs represent ABI’s position.{' '}
                </li>
                <li>
                  <strong className={styles.terms_list}>
                    Don’t use the Tool to generate any output that will be distributed or made available outside of AB
                    InBev:
                  </strong>{' '}
                  Output generated through Insights Copilot should only be used internally at ABI and not distributed or
                  made available to third parties outside of the company. For example, AI outputs should not be used in
                  any commercial context without prior approval, including brands, brand labels, claims, slogans,
                  commercials, ad copy, social media posts, and the like. Additional review (e.g., conduct of IP
                  clearance processes) may be required prior to any commercial or external use.{' '}
                </li>
                <li>
                  <strong className={styles.terms_list}>
                    Don’t simply trust Tool output is accurate or can be used. You are responsible for verifying the
                    accuracy and appropriateness of Tool output before use:
                  </strong>{' '}
                  Generative AI tools can respond in authoritative tones and generate output that seems accurate, but in
                  fact, is false, misleading, biased, irrelevant or otherwise inappropriate. Generative AI, and LLMs
                  specifically, has been known to “hallucinate”, that is, invent compelling and sometimes elaborate
                  responses that are utterly false. Accordingly, you cannot trust that the output generated will be
                  accurate, fair, relevant or otherwise appropriate for your intended use. As the user of the Tool, you
                  are responsible for assessing and verifying the accuracy, fairness and suitability of all outputs. In
                  addition, you cannot trust that the output generated can be lawfully used by AB InBev. For example,
                  output generated using the Tool may infringe third-party intellectual property rights, and
                  AI-generated software code may include third-party opensource technology that could present issues of
                  non-compliance with the underlying open-source license, “copyleft contamination” or other material
                  risks. For these reasons, do not use or rely on output without prior verification of both the
                  relevance, accuracy, fairness and appropriateness of the output, and AB InBev’s right to use it or
                  rely on it. If you have any questions or concerns about any output, please consult your Legal local
                  counsel.{' '}
                </li>
                <li>
                  <strong className={styles.terms_list}>Be transparent:</strong> Consider disclosing that you have used
                  Insights Copilot Application or it’s instances wherever the circumstances may call for it, especially
                  in two occasions: (1) if you use it as an advisor (such as to prepare a first draft of text), or (2)
                  if you use it to substitute considerable portions of a human input (such as if an AI system prepares a
                  whole internal company memorandum for you). You must be transparent about the fact that the work was
                  prepared using an AI system, including how Insights Copilot was used in preparing such work and the
                  extent to which AI outputs were subsequently verified or revised. Please consult your Ethics &
                  Compliance or Legal local counsel.{' '}
                </li>
                <li>
                  <strong className={styles.terms_list}>Ensure fairness, non-discrimination and safety:</strong> Please
                  do not assume that AI outputs are fair or without bias. Insights Copilot provides information derived
                  from public Internet sources, which may reflect societal or other biases (including lack of
                  availability of data for underrepresented populations). You are responsible for ensuring that you use
                  reliable and accurate data that is both sufficient and representative of the situations you aim to
                  cover. Also, prior to use of the Tool’s outputs, you must verify that the outputs do not reflect, and
                  the usage of such outputs would not result in, unfair exclusion or discrimination of people based on
                  age, race, ethnicity, gender, nationality, sexual orientation, ability, political or religious belief
                  and other characteristics. Please review ABI’s Trustworthy AI Guidelines available in global portal
                  for additional guidelines regarding the ethical use of AI tools.{' '}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;
