import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../hooks/useAnalytics';
import { useHandleClickOutside } from '../../hooks/useHandleClickOutside';
import { shareConversationByNodeIds, shareEmails } from '../../redux/features/conversationSlice';
import { showToast } from '../../redux/features/toastSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { ApiResponse, ShareTrace } from '../../services/apiService/definitions/types';
import { Spinner } from '../Spinner';

import styles from './ShareBox.module.css';

type ThumbsDownPopupProps = {
  onClose: () => void;
  type?: string;
  questionNodeId?: string;
  answerNodeId?: string;
};

const MailPopup: React.FC<ThumbsDownPopupProps> = ({ onClose, type, questionNodeId, answerNodeId }) => {
  const popupRef = useHandleClickOutside<HTMLDivElement>({ onClickOutside: onClose });
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversationChain = useSelector((state: RootState) => state.conversation.conversationChain);
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      if (validateEmail(inputValue)) {
        setEmails([...emails, inputValue]);
        setInputValue('');
        setIsInvalidEmail(false);
      } else {
        setIsInvalidEmail(true);
      }
    }
  };

  const analytics = useAnalytics();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
    setIsInvalidEmail(false);
  };

  const handleRemoveEmail = (emailToRemove: string | number) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const allowedDomains = [
    'ab-inbev.com',
    'ambev.com.br',
    'anheuser-busch.com',
    'gcn.ab-inbev.com',
    'gmodelo.com.mx',
    'interbrew.net',
    'na.ab-inbev.com',
    'budweiserapac.com',
    'anheuserbuschinbev.onmicrosoft.com',
    'modelo.gmodelo.com.mx',
  ];

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    const domain = email.split('@')[1].toLowerCase();
    return allowedDomains.includes(domain);
  };

  const handleSendEmails = (emails: string[]) => {
    if (conversationId) {
      const nodeIds =
        type === 'share-chat'
          ? [answerNodeId, questionNodeId].filter((id): id is string => id !== undefined)
          : conversationChain.flatMap((chain) => [chain.queryNodeId, chain.answerNodeId]);
      const lastAnswerNodeId =
        type === 'share-chat' ? answerNodeId : conversationChain[conversationChain.length - 1]?.answerNodeId;
      if (!lastAnswerNodeId) {
        console.error('Error: lastAnswerNodeId is undefined.');
        setIsEmailSent(false);
        return;
      }
      setIsEmailSent(true);
      dispatch(shareConversationByNodeIds({ conversationId: conversationId, nodes: nodeIds })).then((result) => {
        const apiResponse = result.payload as ApiResponse<ShareTrace>;
        if (result.type.endsWith('fulfilled')) {
          dispatch(
            shareEmails({ data: { to: emails, chat_id: lastAnswerNodeId }, traceId: apiResponse.data.trace_id }),
          ).then((emailResult) => {
            if (emailResult.type.endsWith('fulfilled')) {
              dispatch(showToast({ message: 'Successfully shared via email.', type: 'success' }));
            }
            onClose();
            setIsEmailSent(false);
          });
          analytics.trackShareMailDispatchClick(conversationId);
        } else {
          setIsEmailSent(false);
        }
      });
    }
  };

  return createPortal(
    <>
      <div className="popupBG">
        <div className="popup" ref={popupRef}>
          <div className="popupHeader" style={{ marginBottom: 0 }}>
            <p className="popup-title" style={{ flexDirection: 'row', alignItems: 'center', gap: '4px', marginTop: 0 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path
                  className="svg-icon-path"
                  d="M13.5 2.16699H2.83333C2.1 2.16699 1.5 2.76699 1.5 3.50033V11.5003C1.5 12.2337 2.1 12.8337 2.83333 12.8337H8.83333V11.5003H2.83333V4.83366L8.16667 8.16699L13.5 4.83366V8.16699H14.8333V3.50033C14.8333 2.76699 14.2333 2.16699 13.5 2.16699ZM8.16667 6.83366L2.83333 3.50033H13.5L8.16667 6.83366ZM12.8333 9.50033L15.5 12.167L12.8333 14.8337V12.8337H10.1667V11.5003H12.8333V9.50033Z"
                  fill="white"
                />
              </svg>{' '}
              Share via Email
            </p>
            <span className="closeIcon">
              <i
                className="fa-solid fa-xmark"
                onClick={onClose}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    onClose();
                  }
                }}
                tabIndex={0}
              ></i>
            </span>
          </div>
          <div className="line" style={{ marginTop: 'var(--xs)', maxWidth: '520px', width: '100%' }}></div>
          <div className="popup-response-container">
            <div className="popup-response-question" style={{ opacity: '0.5', color: 'var(--neutral-0)' }}>
              Please type an email and press Enter or separate multiple emails with commas.{' '}
            </div>
            <div className="popup-response-answer">
              <div className={styles.inputContainer}>
                <div
                  className={styles.emailChipContainer}
                  style={{
                    border: isInvalidEmail ? '1px solid var(--Secondary-Red-500, #FF3236)' : '',
                  }}
                >
                  <div className={styles.emailChips}>
                    {emails.map((email, index) => (
                      <div className={styles.emailChip} key={index}>
                        {email}
                        <span
                          className={styles.removeChip}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleRemoveEmail(email);
                          }}
                        >
                          x
                        </span>
                      </div>
                    ))}
                  </div>
                  <input
                    placeholder="Enter email"
                    value={inputValue}
                    className={styles.styledInput}
                    type="email"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    className={`${emails.length > 0 ? styles.sendIconActive : styles.sendIcon}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      // dont call this function if isEmailSent is true
                      if (isEmailSent) return;
                      handleSendEmails(emails);
                    }}
                    style={{
                      cursor: emails.length > 0 ? 'pointer' : 'not-allowed',
                      pointerEvents: emails.length > 0 ? 'auto' : 'none',
                    }}
                  >
                    {/* Send icon as SVG or you can use an icon library */}
                    {emails.length > 0 ? (
                      isEmailSent ? (
                        <Spinner />
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                          <path
                            d="M1.38083 12.75L13.625 7.5L1.38083 2.25L1.375 6.33333L10.125 7.5L1.375 8.66667L1.38083 12.75Z"
                            fill="#F5E003"
                          />
                        </svg>
                      )
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path
                          d="M1.38083 12.75L13.625 7.5L1.38083 2.25L1.375 6.33333L10.125 7.5L1.375 8.66667L1.38083 12.75Z"
                          fill="#CDCDCD"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className={styles.invalidEmail}>
                  {isInvalidEmail && <span className={styles.errorText}>Invalid Email, please review the text.</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementsByClassName('App')[0] as HTMLElement,
  );
};

export default MailPopup;
