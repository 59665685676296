import React from 'react';

const CacheIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          className="svg-icon-path"
          d="M9.55151 2L4.57954 2.00595C4.57954 2.00595 3.53875 2.03235 2.77341 2.85324C2.77341 2.85324 0 6.43508 0 7.4007V10.9023C0 11.648 0.730381 11.6292 0.730381 11.6292H12.3488C12.819 11.6292 13.5669 11.6781 13.809 11.3923C13.9193 11.2623 13.9999 11.0628 13.9999 10.7566V7.2565C13.9999 6.29005 11.3025 2.88068 11.3025 2.88068C11.3025 2.88068 10.5904 2 9.55151 2ZM8.7783 8.07521C8.7783 8.07521 8.7783 9.00733 7.90346 9.00733H6.07322C5.76876 9.00733 5.56869 8.9344 5.43758 8.8334C5.14444 8.60698 5.2432 7.85896 4.97562 7.55309C4.71628 7.25655 4.3224 7.25655 4.3224 7.25655H2.49509C2.02488 7.25655 1.82882 6.92345 2.05728 6.51256L3.61935 3.70194C3.61935 3.70194 4.09097 2.87223 4.55064 2.88074H8.75675C9.22696 2.88074 9.84663 3.00276 10.072 3.23601C10.1861 3.35407 10.3095 3.50699 10.4275 3.70194L11.9087 6.50405C12.1283 6.91974 11.9257 7.25655 11.4552 7.25655H9.6534C8.83193 7.25655 8.7783 8.07521 8.7783 8.07521Z"
          fill="#CDCDCD"
        />
        <path
          className="svg-icon-path"
          d="M4.3489 5.50586H9.57003C9.57118 5.50586 10.4727 5.6943 10.4727 6.34861H3.50134C3.50134 6.34861 3.50134 5.50586 4.3489 5.50586Z"
          fill="#CDCDCD"
        />
        <path
          className="svg-icon-path"
          d="M5.22371 3.75586H8.75192C8.75192 3.75586 9.62676 3.94372 9.62676 4.59835H4.3761C4.3761 4.59835 4.3761 3.75586 5.22371 3.75586Z"
          fill="#CDCDCD"
        />
      </svg>
    </>
  );
};

export default CacheIcon;
