import React from 'react';
const ToastSuccessIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.8002 15.8998L4.6002 11.6998L3.2002 13.0998L8.8002 18.6998L20.8002 6.6998L19.4002 5.2998L8.8002 15.8998Z"
        fill="#5FC92E"
      />
    </svg>
  );
};
export default ToastSuccessIcon;
