import React from 'react';

import styles from './Tooltip.module.css';

interface TooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  position?: 'top' | 'bottom' | 'left' | 'right' | 'cacherightspace' | 'cacheright';
}

const Tooltip = ({ children, tooltipText, position = 'right' }: TooltipProps) => {
  return (
    <div className={styles.tooltip}>
      {children}
      <span className={`${styles.tooltiptext} ${styles[position]}`}>{tooltipText}</span>
    </div>
  );
};

export default Tooltip;
