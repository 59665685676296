import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../../hooks/useAnalytics';
import { setSelectedOption, setTheme } from '../../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../../redux/store';

import './selectMenu.css';

interface Option {
  icon: {
    type?: string;
    fill?: string;
    viewBox?: string;
    maskId?: string;
    pathData?: string;
  };
  text: string;
}

export const options: Option[] = [
  {
    icon: {
      type: 'svg',
      fill: '#D9D9D9',
      viewBox: '0 0 10 10',
      maskId: 'mask0_3556_29642', // Example maskId for the first icon
      pathData:
        'M5.45833 9.58333C4.875 9.58333 4.32812 9.47222 3.81771 9.25C3.30729 9.02778 2.86285 8.72743 2.48438 8.34896C2.1059 7.97049 1.80556 7.52604 1.58333 7.01562C1.36111 6.50521 1.25 5.95833 1.25 5.375C1.25 4.36111 1.57292 3.46701 2.21875 2.69271C2.86458 1.9184 3.6875 1.4375 4.6875 1.25C4.5625 1.9375 4.60069 2.60938 4.80208 3.26562C5.00347 3.92188 5.35069 4.49653 5.84375 4.98958C6.33681 5.48264 6.91146 5.82986 7.56771 6.03125C8.22396 6.23264 8.89583 6.27083 9.58333 6.14583C9.40278 7.14583 8.92361 7.96875 8.14583 8.61458C7.36806 9.26042 6.47222 9.58333 5.45833 9.58333ZM5.45833 8.75C6.06944 8.75 6.63542 8.59722 7.15625 8.29167C7.67708 7.98611 8.08681 7.56597 8.38542 7.03125C7.78819 6.97569 7.22222 6.82465 6.6875 6.57812C6.15278 6.3316 5.67361 5.99653 5.25 5.57292C4.82639 5.14931 4.48958 4.67014 4.23958 4.13542C3.98958 3.60069 3.84028 3.03472 3.79167 2.4375C3.25694 2.73611 2.83854 3.14757 2.53646 3.67188C2.23438 4.19618 2.08333 4.76389 2.08333 5.375C2.08333 6.3125 2.41146 7.10937 3.06771 7.76562C3.72396 8.42188 4.52083 8.75 5.45833 8.75Z',
    },
    text: 'Dark Mode',
  },
  {
    icon: {
      type: 'svg',
      viewBox: '0 0 10 10',
      fill: '#D9D9D9',
      maskId: 'mask0_4283_495', // Example maskId for the second icon
      pathData:
        'M4.99996 6.25002C5.34718 6.25002 5.64232 6.12849 5.88538 5.88544C6.12843 5.64238 6.24996 5.34724 6.24996 5.00002C6.24996 4.6528 6.12843 4.35766 5.88538 4.1146C5.64232 3.87155 5.34718 3.75002 4.99996 3.75002C4.65274 3.75002 4.3576 3.87155 4.11454 4.1146C3.87149 4.35766 3.74996 4.6528 3.74996 5.00002C3.74996 5.34724 3.87149 5.64238 4.11454 5.88544C4.3576 6.12849 4.65274 6.25002 4.99996 6.25002ZM4.99996 7.08335C4.42357 7.08335 3.93225 6.88023 3.526 6.47398C3.11975 6.06773 2.91663 5.57641 2.91663 5.00002C2.91663 4.42363 3.11975 3.93231 3.526 3.52606C3.93225 3.11981 4.42357 2.91669 4.99996 2.91669C5.57635 2.91669 6.06767 3.11981 6.47392 3.52606C6.88017 3.93231 7.08329 4.42363 7.08329 5.00002C7.08329 5.57641 6.88017 6.06773 6.47392 6.47398C6.06767 6.88023 5.57635 7.08335 4.99996 7.08335ZM2.08329 5.41669H0.416626V4.58335H2.08329V5.41669ZM9.58329 5.41669H7.91663V4.58335H9.58329V5.41669ZM4.58329 2.08335V0.416687H5.41663V2.08335H4.58329ZM4.58329 9.58335V7.91669H5.41663V9.58335H4.58329ZM2.66663 3.22919L1.61454 2.21877L2.20829 1.60419L3.20829 2.64585L2.66663 3.22919ZM7.79163 8.39585L6.78121 7.34377L7.33329 6.77085L8.38538 7.78127L7.79163 8.39585ZM6.77079 2.66669L7.78121 1.6146L8.39579 2.20835L7.35413 3.20835L6.77079 2.66669ZM1.60413 7.79169L2.65621 6.78127L3.22913 7.33335L2.21871 8.38544L1.60413 7.79169Z',
    },
    text: 'Light Mode',
  },
];

const SelectMenu: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const analytics = useAnalytics();

  const toggleActive = () => {
    setIsActive(!isActive);
    analytics.trackThemeButtonClick(); // Track theme button click
  };

  const selectedOption = useSelector((state: RootState) => state.user.selectedOption);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);

    if (option.text === 'Dark Mode') {
      dispatch(setTheme('dark-theme'));

      localStorage.setItem('theme', 'dark-theme');

      dispatch(setSelectedOption(option));
    } else {
      // return;
      dispatch(setTheme('light-theme'));
      localStorage.setItem('theme', 'light-theme');
      dispatch(setSelectedOption(option));
    }

    setIsActive(false);
  };

  return (
    <div className={`select-menu ${isActive ? 'active' : ''}`}>
      <div
        className="select-btn"
        onClick={toggleActive}
        style={{
          background: 'var(--Greys-Top-700, #272727)',
        }}
      >
        <span className="sBtn-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <mask id="mask0_4283_495" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
              <rect width="10" height="10" fill={selectedOption?.icon.fill} />
            </mask>
            <g mask={`url(#${selectedOption?.icon.maskId})`}>
              <path className="svg-icon-path" d={selectedOption?.icon.pathData} fill="#CBCBCB" />
            </g>
          </svg>
          {selectedOption?.text}
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <mask id="mask0_3556_28762" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
              <rect width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3556_28762)">
              <path
                className="svg-icon-path"
                d="M7 8.98323L3.5 5.48323L4.31667 4.66656L7 7.3499L9.68333 4.66656L10.5 5.48323L7 8.98323Z"
                fill="#B7B7B7"
              />
            </g>
          </svg>
        </span>
      </div>
      {isActive && (
        <ul
          className="options theme-toggle"
          style={{
            background: 'var(--Greys-Top-700, #272727)',
          }}
        >
          {options.map((option, index) => (
            <li
              key={index}
              className={`option`}
              // className={`option ${index === 1 ? 'disabled' : ''}`}
              onClick={(e) => {
                e.stopPropagation();

                handleOptionClick(option);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <mask id="mask0_4283_495" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
                  <rect width="10" height="10" fill={option?.icon.fill} />
                </mask>
                <g mask={`url(#${option?.icon.maskId})`}>
                  <path className="svg-icon-path" d={option?.icon.pathData} fill="#CBCBCB" />
                </g>
              </svg>
              <span className="option-text">{option.text}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectMenu;
