import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSearchQuery } from '../../../redux/features/sideBar';
import { AppDispatch, RootState } from '../../../redux/store';

import styles from './Search.module.css';

type SearchProps = {
  isCollapsed: boolean;
  handleCollapseClicksearch: () => void;
  handleCollapseClick: () => void;
  inputRef: any;
};

export default function Search({ isCollapsed, handleCollapseClicksearch, handleCollapseClick, inputRef }: SearchProps) {
  const dispatch = useDispatch<AppDispatch>();

  const searchQuery = useSelector((state: RootState) => state.sideBar.searchQuery);
  const ClickArrow = () => {
    return (
      <div className={styles.collapse_container}>
        <div className={styles.collapse}>
          <button className={styles.collapse_button} onClick={handleCollapseClick}>
            <i
              className={`${isCollapsed ? 'fa-solid fa-angle-right' : 'fa-solid fa-angle-left'}`}
              style={{ color: ' var( --neutral-0)' }}
            ></i>
          </button>
        </div>
      </div>
    );
  };

  if (isCollapsed) {
    return (
      <>
        <div className={styles.onlysearch_container}>
          <button className={styles.onlysearch} onClick={handleCollapseClicksearch}>
            <i
              className="fa-solid fa-search"
              style={{
                color: '#818181',
              }}
            />
          </button>
        </div>
        <div
          className={`${styles.search_and_collapse_container} ${isCollapsed ? styles.search_and_collapse_container_collapse : styles.search_and_collapse_containernocollapse}`}
        >
          <ClickArrow />
        </div>
      </>
    );
  }

  return (
    <div
      className={`${styles.search_and_collapse_container} ${isCollapsed ? styles.search_and_collapse_container_collapse : styles.search_and_collapse_containernocollapse}`}
    >
      <div className={styles.search_container}>
        <div style={{ position: 'relative' }}>
          <i
            className="fa-solid fa-search"
            style={{
              color: 'var(--fixed)',
              position: 'absolute',
              top: 13,
              left: 15,
              fontSize: 'var(--Info-14)',
            }}
          ></i>
          <input
            type="text"
            name="search"
            className={styles.inputnosubmit}
            id="search_input_field"
            placeholder="Search"
            value={searchQuery}
            style={{ display: isCollapsed ? 'none' : 'block' }}
            autoComplete="off"
            onChange={(e) => dispatch(setSearchQuery(e.target.value))}
            ref={inputRef}
          />
        </div>
      </div>

      <ClickArrow />
    </div>
  );
}
