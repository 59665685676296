import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';

import useAnalytics from '../../hooks/useAnalytics';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useHandleClickOutside } from '../../hooks/useHandleClickOutside';
import { shareConversationByNodeIds } from '../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { ApiResponse, ShareTrace } from '../../services/apiService/definitions/types';

import MailPopup from './MailPopup';

// import ShareToTeamsButton from './ShareToTeams';
import styles from './ShareBox.module.css';

interface ShareBoxProps {
  position: 'left' | 'right' | 'center';
  answerNodeId?: string;
  questionNodeId?: string;
  type?: string;
  isChat?: boolean;
}

const ShareBox: React.FC<ShareBoxProps> = ({ position, answerNodeId, questionNodeId, type, isChat }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMailPopupVisible, setIsMailPopupVisible] = useState(false);
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversationChain = useSelector((state: RootState) => state.conversation.conversationChain);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const dispatch = useDispatch<AppDispatch>();
  const shareContainerRef = useHandleClickOutside<HTMLDivElement>({ onClickOutside: () => setIsVisible(false) });
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const analytics = useAnalytics();

  const toggleShareBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsVisible(!isVisible);
    analytics.trackShareButtonClick(conversationId);
  };

  const copyConversationLink = () => {
    const handleShareSuccess = (result: PayloadAction<ApiResponse<ShareTrace>>) => {
      if (result.type.endsWith('fulfilled')) {
        const link = `${window.location.origin}/chat/share/${result.payload.data.trace_id}?utm_source=copy_link&utm_medium=social`;
        copyToClipboard(link);
        analytics.trackShareCopyLinkClick(conversationId);
      }
    };

    if (conversationId) {
      const nodeIds =
        type === 'share-chat'
          ? [answerNodeId, questionNodeId].filter((id): id is string => id !== undefined)
          : conversationChain.flatMap((chain) => [chain.queryNodeId, chain.answerNodeId]);
      dispatch(shareConversationByNodeIds({ conversationId: conversationId, nodes: nodeIds })).then((result) =>
        handleShareSuccess(result as PayloadAction<ApiResponse<ShareTrace>>),
      );
    }
  };
  const toggleMailPopup = () => {
    setIsMailPopupVisible(!isMailPopupVisible);
    analytics.trackShareMailPopupClick(conversationId);
  };

  // commenting it for now , can be used later

  // const handleTeamsClick = async () => {
  //   if (!conversationId) return;

  //   try {
  //     const nodeIds =
  //       type === 'share-chat'
  //         ? ([questionNodeId, answerNodeId].filter(Boolean) as string[])
  //         : conversationChain.flatMap((chain) => [chain.queryNodeId, chain.answerNodeId]);

  //     const result = await dispatch(shareConversationByNodeIds({ conversationId, nodes: nodeIds }));

  //     if (result.type.endsWith('fulfilled')) {
  //       setIsTeamsShareEnabled(true);
  //       const payload = result.payload as { data: { trace_id: string } };
  //       setShareLink(
  //         `${window.location.origin}/chat/share/${payload.data.trace_id}?utm_source=teams&utm_medium=social`,
  //       );
  //     } else {
  //       console.error('Failed to share conversation:', result.payload);
  //     }
  //   } catch (err) {
  //     console.error('Unexpected error:', err);
  //   }
  // };
  return (
    <div className={styles.sharebuttoncontainer} ref={shareContainerRef}>
      <button
        onClick={toggleShareBox}
        disabled={!isChat && isQuestionSubmitted}
        className={`${styles.sharebutton} ${isVisible ? 'active' : ''}`}
        style={{
          marginRight: position === 'right' ? '10px' : '',
          cursor: !isChat && isQuestionSubmitted ? 'not-allowed' : 'pointer',
        }}
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="share">
            <path
              className="svg-icon-path"
              id="Vector"
              d="M13.5 12.09C12.93 12.09 12.42 12.315 12.03 12.6675L6.6825 9.55503C6.72 9.38253 6.75 9.21003 6.75 9.03003C6.75 8.85003 6.72 8.67753 6.6825 8.50503L11.97 5.42253C12.375 5.79753 12.9075 6.03003 13.5 6.03003C14.745 6.03003 15.75 5.02503 15.75 3.78003C15.75 2.53503 14.745 1.53003 13.5 1.53003C12.255 1.53003 11.25 2.53503 11.25 3.78003C11.25 3.96003 11.28 4.13253 11.3175 4.30503L6.03 7.38753C5.625 7.01253 5.0925 6.78003 4.5 6.78003C3.255 6.78003 2.25 7.78503 2.25 9.03003C2.25 10.275 3.255 11.28 4.5 11.28C5.0925 11.28 5.625 11.0475 6.03 10.6725L11.37 13.7925C11.3325 13.95 11.31 14.115 11.31 14.28C11.31 15.4875 12.2925 16.47 13.5 16.47C14.7075 16.47 15.69 15.4875 15.69 14.28C15.69 13.0725 14.7075 12.09 13.5 12.09ZM13.5 3.03003C13.9125 3.03003 14.25 3.36753 14.25 3.78003C14.25 4.19253 13.9125 4.53003 13.5 4.53003C13.0875 4.53003 12.75 4.19253 12.75 3.78003C12.75 3.36753 13.0875 3.03003 13.5 3.03003ZM4.5 9.78003C4.0875 9.78003 3.75 9.44253 3.75 9.03003C3.75 8.61753 4.0875 8.28003 4.5 8.28003C4.9125 8.28003 5.25 8.61753 5.25 9.03003C5.25 9.44253 4.9125 9.78003 4.5 9.78003ZM13.5 15.045C13.0875 15.045 12.75 14.7075 12.75 14.295C12.75 13.8825 13.0875 13.545 13.5 13.545C13.9125 13.545 14.25 13.8825 14.25 14.295C14.25 14.7075 13.9125 15.045 13.5 15.045Z"
              fill="#818181"
              fillOpacity="0.54"
            />
          </g>
        </svg>
      </button>
      {isVisible && (
        <div
          className={`${styles.sharebox} ${styles[position]}`}
          // style={{
          //   left: position === 'right' ? '268px' : position === 'center' ? '115px' : '',
          //   marginTop: position === 'left' ? '24px' : position === 'center' ? '-38px' : '-8px',
          //   right: position === 'left' ? '305px' : '',
          // }}
        >
          <button onClick={copyConversationLink} className={styles.shareboxButton}>
            {isCopied ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 13" fill="none">
                <path
                  d="M4.39754 8.43746L2.31254 6.35246L1.60254 7.05746L4.39754 9.85246L10.3975 3.85246L9.69254 3.14746L4.39754 8.43746Z"
                  fill="#5FC92E"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 13" fill="none">
                <path
                  className="svg-icon-path"
                  d="M8.5 4H6.5V5H8.5C9.325 5 10 5.675 10 6.5C10 7.325 9.325 8 8.5 8H6.5V9H8.5C9.88 9 11 7.88 11 6.5C11 5.12 9.88 4 8.5 4ZM5.5 8H3.5C2.675 8 2 7.325 2 6.5C2 5.675 2.675 5 3.5 5H5.5V4H3.5C2.12 4 1 5.12 1 6.5C1 7.88 2.12 9 3.5 9H5.5V8ZM4 6H8V7H4V6Z"
                  fill="white"
                />
              </svg>
            )}
          </button>
          <>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleMailPopup();
              }}
              className={styles.shareboxButton}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 13" fill="none">
                <path
                  className="svg-icon-path"
                  d="M9.75 1.75H1.75C1.2 1.75 0.75 2.2 0.75 2.75V8.75C0.75 9.3 1.2 9.75 1.75 9.75H6.25V8.75H1.75V3.75L5.75 6.25L9.75 3.75V6.25H10.75V2.75C10.75 2.2 10.3 1.75 9.75 1.75ZM5.75 5.25L1.75 2.75H9.75L5.75 5.25ZM9.25 7.25L11.25 9.25L9.25 11.25V9.75H7.25V8.75H9.25V7.25Z"
                  fill="white"
                />
              </svg>
            </button>
          </>
          {/* <>
            <ShareToTeamsButton
              iconElement={
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="svg-icon-path"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4613 10.6562C14.4796 10.7084 14.4884 10.7636 14.4871 10.819V13.9375C14.4974 14.3978 14.3921 14.8487 14.1864 15.2514C14.3014 15.2754 14.4188 15.2876 14.5369 15.2875H14.5444C14.7681 15.2877 14.9898 15.2438 15.1966 15.1583C15.4034 15.0729 15.5913 14.9475 15.7497 14.7894C15.9081 14.6314 16.0338 14.4436 16.1196 14.237C16.2054 14.0303 16.2497 13.8087 16.2499 13.585V11.119C16.2499 10.8632 16.0421 10.6562 15.7856 10.6562H14.4613ZM16.0054 9.07223C16.0054 9.67748 15.5134 10.1687 14.9074 10.1687H14.9066C14.2999 10.1687 13.8079 9.67748 13.8079 9.07223C13.8079 8.46623 14.2999 7.97498 14.9066 7.97498C15.5134 7.97498 16.0054 8.46623 16.0054 9.07223Z"
                    fill="white"
                  />
                  <path
                    className="svg-icon-path"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.84131 14.9286C8.97001 15.2872 9.173 15.6186 9.4412 15.8994C9.94477 16.4268 10.637 16.7327 11.366 16.75C12.0951 16.7329 12.7875 16.4271 13.2913 15.8997C13.795 15.3723 14.0688 14.6666 14.0525 13.9375V11.125C14.0539 11.0648 14.0434 11.005 14.0217 10.9488C13.9999 10.8927 13.9673 10.8414 13.9257 10.7979C13.8842 10.7543 13.8344 10.7194 13.7793 10.6951C13.7242 10.6708 13.6649 10.6576 13.6047 10.6562H11.5144V14.4927C11.5137 14.6082 11.4583 14.7189 11.3604 14.8005C11.2625 14.8822 11.1299 14.9282 10.9915 14.9286H8.84131Z"
                    fill="white"
                  />
                  <path
                    className="svg-icon-path"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.9171 8.81473H11.0616C11.1798 8.81512 11.293 8.86214 11.3767 8.94556C11.4604 9.02898 11.5078 9.14281 11.5086 9.26098V10.1687C11.7099 10.1663 11.909 10.1257 12.0952 10.0489C12.2878 9.9694 12.4627 9.8528 12.6102 9.7057C12.7576 9.5586 12.8746 9.38389 12.9546 9.19156C13.0345 8.99922 13.0757 8.79303 13.0759 8.58475C13.0758 8.37635 13.0346 8.17002 12.9547 7.97755C12.8748 7.78507 12.7577 7.61025 12.6102 7.46306C12.4626 7.31587 12.2875 7.19921 12.0949 7.11976C11.9022 7.0403 11.6958 6.99961 11.4874 7C11.2792 6.99981 11.073 7.04062 10.8805 7.12012C10.6881 7.19961 10.5132 7.31624 10.3658 7.46332C10.2185 7.61041 10.1015 7.78509 10.0217 7.97737C9.94179 8.16966 9.90059 8.37579 9.90039 8.584C9.90039 8.66237 9.90609 8.73941 9.9171 8.81473Z"
                    fill="white"
                  />
                  <path
                    className="svg-icon-path"
                    opacity="0.2"
                    d="M11.1222 9.63998V10.1252C10.8785 10.0677 10.6519 9.95329 10.461 9.79139C10.27 9.62949 10.1201 9.42468 10.0234 9.19373H10.6759C10.7941 9.19432 10.9073 9.24152 10.9908 9.32508C11.0744 9.40864 11.1216 9.5218 11.1222 9.63998Z"
                    fill="white"
                  />
                  <path
                    className="svg-icon-path"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6745 9.19373H6.19775C5.95025 9.19373 5.75 9.39323 5.75 9.64073V14.1092C5.75 14.1679 5.76156 14.2261 5.78403 14.2803C5.80649 14.3345 5.83942 14.3838 5.88092 14.4253C5.92243 14.4668 5.97171 14.4997 6.02594 14.5222C6.08017 14.5447 6.1383 14.5562 6.197 14.5562H10.6745C10.7333 14.5563 10.7915 14.5448 10.8458 14.5224C10.9001 14.5 10.9495 14.4671 10.9911 14.4256C11.0326 14.384 11.0656 14.3347 11.0882 14.2805C11.1107 14.2262 11.1223 14.168 11.1223 14.1092V9.64073C11.1223 9.39398 10.922 9.19373 10.6745 9.19373ZM8.71928 10.8948H9.61403L9.61328 10.4223H7.25753V10.8948H8.14928V13.3271H8.71928V10.8948Z"
                    fill="white"
                  />
                </svg>
              }
              handleTeamsClick={handleTeamsClick}
              isTeamsShareEnabled={isTeamsShareEnabled}
              shareLink={shareLink}
            />
          </> */}
          {/* <button onClick={exportPdf} className={styles.shareboxButton}>
            {!isPdfExported ? (
              <Spinner />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 13" fill="none">
                <path
                  className="svg-icon-path"
                  d="M10.25 8.12533V9.75033H3.74999V8.12533H2.66666V9.75033C2.66666 10.3462 3.15416 10.8337 3.74999 10.8337H10.25C10.8458 10.8337 11.3333 10.3462 11.3333 9.75033V8.12533H10.25ZM9.70832 5.95866L8.94457 5.19491L7.54166 6.59241V2.16699H6.45832V6.59241L5.05541 5.19491L4.29166 5.95866L6.99999 8.66699L9.70832 5.95866Z"
                  fill="white"
                />
              </svg>
            )}
          </button> */}
        </div>
      )}
      {isMailPopupVisible && (
        <MailPopup onClose={toggleMailPopup} type={type} answerNodeId={answerNodeId} questionNodeId={questionNodeId} />
      )}
    </div>
  );
};

export default ShareBox;
