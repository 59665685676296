import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetConversationState } from '../../redux/features/conversationSlice';
import { deleteAllChatHistory } from '../../redux/features/historySlice';
import { AppDispatch, RootState } from '../../redux/store';
import { stopRunningCalls } from '../../services/apiService/globals';

interface feedprops {
  setShowDeleteConfirmation: (value: boolean) => void;
}

const DeleteConfirmationPopup: React.FC<feedprops> = ({ setShowDeleteConfirmation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userAccount = useSelector((state: RootState) => state.user.userData);
  const selectedProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const popupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        // Click occurred outside the popup, close it
        setShowDeleteConfirmation(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleDelete = async () => {
    try {
      if (userAccount) {
        await dispatch(
          deleteAllChatHistory({
            projectKey: selectedProjectKey,
          }),
        )
          .unwrap()
          .then(() => {
            setShowDeleteConfirmation(false);
            stopRunningCalls();
            navigate('/chat');
            dispatch(resetConversationState());
          });
      }
    } catch (error) {
      console.error('Error dispatching action:', error);
    }
  };

  return createPortal(
    <div className="popupBG">
      <div
        className="success-popup"
        ref={popupRef}
        style={{
          maxWidth: '216px',
          background: 'var(--Greys-Base-BG-800)',
          padding: '10px 16px 14px 16px',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="popupHeader"
          style={{
            fontSize: 'var(--Info-12)',
            fontWeight: '600',
            lineHeight: '20px',
            color: ' var( --neutral-0)',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          Clear all chat history?
          <span className="closeIcon" style={{ position: 'absolute', right: '0' }}>
            <i className="fa-solid fa-xmark" onClick={() => setShowDeleteConfirmation(false)} tabIndex={0}></i>
          </span>
        </div>
        <div className="line" style={{ maxWidth: '163px', marginTop: '0' }}></div>
        <div className="popup-response-container">
          <div className="success-popup-container">
            <div className="success-message">
              <p
                className="success-message-feed"
                style={{
                  color: 'var(--Secondary-Red-300, #FF9783)',
                  fontSize: 'var(--Info-12)',
                  fontWeight: '600',
                  lineHeight: '20px',
                }}
              >
                This action can’t be undone!
              </p>
              <p className="success-message-res" style={{ display: 'flex', gap: 'var(--Info-12)' }}>
                <button
                  style={{
                    display: 'flex',
                    width: '74px',
                    padding: '3px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    borderRadius: '3px',
                    background: ' var(--Greys-Base-BG-700)',
                    border: 'none',
                    color: ' var( --neutral-0)',
                    fontSize: 'var(--Info-12)',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleDelete();
                  }}
                  style={{
                    display: 'flex',
                    width: '74px',
                    padding: '3px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    borderRadius: '3px',
                    background: '#342121',
                    border: 'none',
                    color: '#FF6F65',
                    fontSize: 'var(--Info-12)',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <mask id="mask0_3880_27597" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
                      <rect x="0.805176" y="0.551514" width="12" height="12" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_3880_27597)">
                      <path
                        d="M4.30505 11.0515C4.03005 11.0515 3.79464 10.9536 3.5988 10.7578C3.40297 10.5619 3.30505 10.3265 3.30505 10.0515V3.55151H2.80505V2.55151H5.30505V2.05151H8.30505V2.55151H10.8051V3.55151H10.3051V10.0515C10.3051 10.3265 10.2071 10.5619 10.0113 10.7578C9.81547 10.9536 9.58005 11.0515 9.30505 11.0515H4.30505ZM9.30505 3.55151H4.30505V10.0515H9.30505V3.55151ZM5.30505 9.05151H6.30505V4.55151H5.30505V9.05151ZM7.30505 9.05151H8.30505V4.55151H7.30505V9.05151Z"
                        fill="#FF6F65"
                      />
                    </g>
                  </svg>{' '}
                  Delete
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementsByClassName('App')[0] as HTMLElement,
  );
};

export default DeleteConfirmationPopup;
