import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import {
  mergeViewSourceState,
  regenerateChat,
  setViewSourceState,
  updateChatFeedback,
} from '../../redux/features/conversationSlice';
import { changeAnswerNodeInChain } from '../../redux/features/conversationSlice';
import { setisCollapsedState } from '../../redux/features/sideBar';
import { AppDispatch, RootState } from '../../redux/store';
import { getNodesOfType, sortNodeIdsByCreationTimeAsc } from '../../utils/conversation';
import { formatCacheDate } from '../../utils/utils';
import FeedbackSuccessPopup from '../Feedback/FeedbackSuccessPopup';
import ThumbsDownPopup from '../Feedback/ThumbsDownPopup';
import ShareBox from '../Share/ShareBox';
import { VersionSwitcher } from '../VersionSwitcher';
import { getSourcesFromResponse } from '../ViewSourceReasoning/utils/getSourcesFromResponse';

import CacheIcon from './CacheIcon';

import './MsgFooter.css';
import styles from './MsgFooter.module.css';

type Props = {
  queryNodeId: string;
  answerNodeId: string;
};

const MessageFooter: React.FC<Props> = ({ queryNodeId, answerNodeId }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const [showPopup, setShowPopup] = useState(false);
  const [positiveFeedback, setPositiveFeedback] = useState(false);
  const [negativeFeedback, setNegativeFeedback] = useState(false);
  const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const conversationTreeNodeStates = useSelector((state: RootState) => state.conversation.conversationTreeNodeStates);
  const viewSourceState = useSelector((state: RootState) => state.conversation.viewSourceState);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const isPro = useSelector((state: RootState) => state.user.isPro);
  const answerNode = conversationTree?.nodes[answerNodeId];
  const answerNodeState = conversationTreeNodeStates[answerNodeId];
  const receipient = useSelector((state: RootState) => state.conversation.isReceipient);
  const userEmail = useSelector((state: RootState) => state.user.userData?.email)?.toLowerCase();
  const registered = useSelector((state: RootState) => state.user.registered);

  const siblingAnswerNodes = conversationTree
    ? getNodesOfType(conversationTree.nodes[queryNodeId].children, conversationTree, 'answer')
    : [];
  const siblingAnswerNodesSorted = conversationTree
    ? sortNodeIdsByCreationTimeAsc(
        siblingAnswerNodes.map((node) => node.id),
        conversationTree,
      )
    : [];
  const answerNodePosition = siblingAnswerNodesSorted.findIndex((nodeId) => nodeId === answerNodeId);
  const nextAnswerNode = siblingAnswerNodesSorted[answerNodePosition + 1] || null;
  const prevAnswerNode = siblingAnswerNodesSorted[answerNodePosition - 1] || null;

  const onNextVersionClick = () => {
    if (nextAnswerNode) {
      dispatch(changeAnswerNodeInChain({ currentQueryNodeId: queryNodeId, newAnswerNodeId: nextAnswerNode }));
    }
  };

  const onPrevVersionClick = () => {
    if (prevAnswerNode) {
      dispatch(changeAnswerNodeInChain({ currentQueryNodeId: queryNodeId, newAnswerNodeId: prevAnswerNode }));
    }
  };

  const renderAnswerVersion = () => {
    if (siblingAnswerNodesSorted.length > 1) {
      return (
        <VersionSwitcher
          current={answerNodePosition + 1}
          total={siblingAnswerNodesSorted.length}
          isNextAvailable={!!nextAnswerNode}
          isPrevAvailable={!!prevAnswerNode}
          onNextClick={onNextVersionClick}
          onPrevClick={onPrevVersionClick}
        />
      );
    }
    return null;
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleCopyToClipboard = () => {
    let textToCopy = '';
    answerNode?.data.engine_response?.response?.forEach((item: any) => {
      textToCopy += `${item.key_name}: ${item.key_value}\n`;
    });
    copyToClipboard(textToCopy);
  };

  const regenerateChatHandler = async () => {
    if (isQuestionSubmitted || !answerNode?.parent) {
      return;
    }
    await dispatch(
      regenerateChat({
        queryNodeId: answerNode?.parent,
        body: { is_pro: isPro },
      }),
    );
  };

  const sendPostiveFeedback = () => {
    dispatch(
      updateChatFeedback({
        answerNodeId: answerNodeId,
        body: {
          chat_id: answerNodeId,
          feedback_sentiment: true,
          feedback_msg: '',
          feedbackOptions: {
            not_factual: false,
            not_detailed: false,
            not_actionable: false,
          },
        },
      }),
    )
      .then((result) => {
        if (result.type.endsWith('fulfilled')) {
          setPositiveFeedback(true);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.error('Error submitting feedback', error);
      });
  };

  const handleViewSourceClick = () => {
    if (viewSourceState?.show && viewSourceState?.answerNodeId === answerNodeId) {
      dispatch(setViewSourceState(null));
    } else {
      dispatch(mergeViewSourceState({ show: true, answerNodeId }));
    }
    dispatch(setisCollapsedState(true));
  };

  const engineResponse = answerNode?.data.engine_response;
  const allSources = getSourcesFromResponse(engineResponse);
  const isChatOwner =
    conversationTree && conversationTree.nodes[answerNodeId].data.user_email.toLowerCase() === userEmail;
  return (
    <div className="msgBodyFooter">
      <div className="msgBodyFooter__options">
        <div className="msgBodyFooter__options__copy">
          {!receipient && registered && isChatOwner && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!positiveFeedback) {
                  sendPostiveFeedback();
                }
              }}
              className={styles['icon-button']}
            >
              {positiveFeedback ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14" fill="none">
                  <path
                    className="svg-icon-path"
                    d="M11 13.9998H4.33331V5.33317L8.99998 0.666504L9.83331 1.49984C9.91109 1.57761 9.97498 1.68317 10.025 1.8165C10.075 1.94984 10.1 2.07761 10.1 2.19984V2.43317L9.36665 5.33317H13C13.3555 5.33317 13.6666 5.4665 13.9333 5.73317C14.2 5.99984 14.3333 6.31095 14.3333 6.6665V7.99984C14.3333 8.07761 14.325 8.16095 14.3083 8.24984C14.2916 8.33873 14.2666 8.42206 14.2333 8.49984L12.2333 13.1998C12.1333 13.4221 11.9666 13.6109 11.7333 13.7665C11.5 13.9221 11.2555 13.9998 11 13.9998ZM2.99998 5.33317V13.9998H0.333313V5.33317H2.99998Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14" fill="none">
                  <path
                    className="svg-icon-path"
                    d="M11 14.0003H3.66665V5.33366L8.33331 0.666992L9.16665 1.50033C9.24442 1.5781 9.30831 1.68366 9.35831 1.81699C9.40831 1.95033 9.43331 2.0781 9.43331 2.20033V2.43366L8.69998 5.33366H13C13.3555 5.33366 13.6666 5.46699 13.9333 5.73366C14.2 6.00033 14.3333 6.31144 14.3333 6.66699V8.00033C14.3333 8.0781 14.3222 8.16144 14.3 8.25033C14.2778 8.33921 14.2555 8.42255 14.2333 8.50033L12.2333 13.2003C12.1333 13.4225 11.9666 13.6114 11.7333 13.767C11.5 13.9225 11.2555 14.0003 11 14.0003ZM4.99998 12.667H11L13 8.00033V6.66699H6.99998L7.89998 3.00033L4.99998 5.90033V12.667ZM3.66665 5.33366V6.66699H1.66665V12.667H3.66665V14.0003H0.333313V5.33366H3.66665Z"
                    fill="white"
                    fillOpacity="0.54"
                  />
                </svg>
              )}
            </button>
          )}

          {!receipient && registered && isChatOwner && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!negativeFeedback) {
                  togglePopup();
                }
              }}
              className={styles['icon-button']}
            >
              {negativeFeedback ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14" fill="none">
                  <path
                    className="svg-icon-path"
                    d="M4.00002 0H10.6667V8.66667L6.00002 13.3333L5.16669 12.5C5.08891 12.4222 5.02502 12.3167 4.97502 12.1833C4.92502 12.05 4.90002 11.9222 4.90002 11.8V11.5667L5.63335 8.66667H2.00002C1.64446 8.66667 1.33335 8.53333 1.06669 8.26667C0.80002 8 0.666687 7.68889 0.666687 7.33333V6C0.666687 5.92222 0.67502 5.83889 0.691687 5.75C0.708354 5.66111 0.733354 5.57778 0.766687 5.5L2.76669 0.8C2.86669 0.577778 3.03335 0.388889 3.26669 0.233333C3.50002 0.0777778 3.74446 0 4.00002 0ZM12 8.66667V0H14.6667V8.66667H12Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 15 14" fill="none">
                  <path
                    className="svg-icon-path"
                    d="M4.00002 0H11.3334V8.66667L6.66669 13.3333L5.83335 12.5C5.75558 12.4222 5.69169 12.3167 5.64169 12.1833C5.59169 12.05 5.56669 11.9222 5.56669 11.8V11.5667L6.30002 8.66667H2.00002C1.64446 8.66667 1.33335 8.53333 1.06669 8.26667C0.80002 8 0.666687 7.68889 0.666687 7.33333V6C0.666687 5.92222 0.677798 5.83889 0.70002 5.75C0.722243 5.66111 0.744465 5.57778 0.766687 5.5L2.76669 0.8C2.86669 0.577778 3.03335 0.388889 3.26669 0.233333C3.50002 0.0777778 3.74446 0 4.00002 0ZM10 1.33333H4.00002L2.00002 6V7.33333H8.00002L7.10002 11L10 8.1V1.33333ZM11.3334 8.66667V7.33333H13.3334V1.33333H11.3334V0H14.6667V8.66667H11.3334Z"
                    fill="white"
                    fillOpacity="0.54"
                  />
                </svg>
              )}
            </button>
          )}
          {
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!isCopied) {
                  handleCopyToClipboard();
                }
              }}
              className={styles['icon-button']}
            >
              {isCopied ? (
                <i className="fa-solid fa-check" />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 14" fill="none">
                  <path
                    className="svg-icon-path"
                    d="M4 10.9997C3.63333 10.9997 3.31944 10.8691 3.05833 10.608C2.79722 10.3469 2.66667 10.033 2.66667 9.66634V1.66634C2.66667 1.29967 2.79722 0.985786 3.05833 0.724674C3.31944 0.463563 3.63333 0.333008 4 0.333008H10C10.3667 0.333008 10.6806 0.463563 10.9417 0.724674C11.2028 0.985786 11.3333 1.29967 11.3333 1.66634V9.66634C11.3333 10.033 11.2028 10.3469 10.9417 10.608C10.6806 10.8691 10.3667 10.9997 10 10.9997H4ZM4 9.66634H10V1.66634H4V9.66634ZM1.33333 13.6663C0.966667 13.6663 0.652778 13.5358 0.391667 13.2747C0.130556 13.0136 0 12.6997 0 12.333V2.99967H1.33333V12.333H8.66667V13.6663H1.33333Z"
                    fill="white"
                    fillOpacity="0.54"
                  />
                </svg>
              )}
            </button>
          }
          {!receipient && registered && isChatOwner && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                regenerateChatHandler();
              }}
              className={styles['icon-button']}
              disabled={isQuestionSubmitted}
            >
              <svg width="18" height="18" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  className="svg-icon-path"
                  d="M1.39996 10.7003C1.15551 10.2781 0.972182 9.84477 0.849959 9.40033C0.727737 8.95588 0.666626 8.50033 0.666626 8.03366C0.666626 6.54477 1.18329 5.2781 2.21663 4.23366C3.24996 3.18921 4.51107 2.66699 5.99996 2.66699H6.11663L5.04996 1.60033L5.98329 0.666992L8.64996 3.33366L5.98329 6.00033L5.04996 5.06699L6.11663 4.00033H5.99996C4.88885 4.00033 3.9444 4.39199 3.16663 5.17533C2.38885 5.95866 1.99996 6.91144 1.99996 8.03366C1.99996 8.32255 2.03329 8.60588 2.09996 8.88366C2.16663 9.16144 2.26663 9.43366 2.39996 9.70033L1.39996 10.7003ZM6.01663 15.3337L3.34996 12.667L6.01663 10.0003L6.94996 10.9337L5.88329 12.0003H5.99996C7.11107 12.0003 8.05551 11.6087 8.83329 10.8253C9.61107 10.042 9.99996 9.08921 9.99996 7.96699C9.99996 7.6781 9.96663 7.39477 9.89996 7.11699C9.83329 6.83921 9.73329 6.56699 9.59996 6.30033L10.6 5.30033C10.8444 5.72255 11.0277 6.15588 11.15 6.60033C11.2722 7.04477 11.3333 7.50033 11.3333 7.96699C11.3333 9.45588 10.8166 10.7225 9.78329 11.767C8.74996 12.8114 7.48885 13.3337 5.99996 13.3337H5.88329L6.94996 14.4003L6.01663 15.3337Z"
                  fill="white"
                  fillOpacity="0.54"
                />
              </svg>
            </button>
          )}
          {!receipient && registered && isChatOwner && (
            <ShareBox
              position="center"
              answerNodeId={answerNodeId}
              questionNodeId={conversationTree.nodes[answerNodeId].parent || undefined}
              type="share-chat"
              isChat={true}
            />
          )}

          {renderAnswerVersion()}
        </div>

        {registered && allSources.length > 0 && (
          <div
            className="view-source"
            onClick={(e) => {
              e.stopPropagation();
              handleViewSourceClick();
            }}
          >
            {viewSourceState?.show && viewSourceState?.answerNodeId === answerNodeId ? 'Hide Sources' : 'View Sources'}
          </div>
        )}
      </div>

      {answerNodeState?.cacheDate && (
        <div className="msgBodyFooter__options__cache">
          <div className="msgBodyFooter__options__cache__icon">
            <CacheIcon />
          </div>
          <div className="text">
            Retrieved from cache <span>{formatCacheDate(answerNodeState?.cacheDate)}</span>
          </div>
        </div>
      )}

      {showPopup && (
        <ThumbsDownPopup
          answerNodeId={answerNodeId}
          onClose={togglePopup}
          setNegativeFeedback={setNegativeFeedback}
          setShowFeedbackSuccess={setShowFeedbackSuccess}
        />
      )}

      {showFeedbackSuccess && <FeedbackSuccessPopup setShowFeedbackSuccess={setShowFeedbackSuccess} />}
    </div>
  );
};

export default MessageFooter;
