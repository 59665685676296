import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsMenuOpen, setIsSettingsOpen } from '../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../redux/store';
import UserAvatar from '../Core/userAvatar/UserAvatar';

// import ProfileContainer from './ProfileContainer';

const ProfileMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const isProfileOpen = useSelector((state: RootState) => state.user.isProfileOpen);
  const isSettingsOpen = useSelector((state: RootState) => state.user.isSettingsOpen);
  const isMenuOpen = useSelector((state: RootState) => state.user.isMenuOpen);
  // we open settings on click of profile button
  return (
    <>
      <div className="profile">
        <div
          className="profile-button"
          onClick={(e) => {
            e.stopPropagation();
            if (isMenuOpen) {
              dispatch(setIsMenuOpen(false));
            }
            dispatch(setIsSettingsOpen(!isSettingsOpen));
          }}
        >
          <UserAvatar isChatOwner={true} />
        </div>
      </div>
    </>
  );
};

export default ProfileMenu;
