import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LogoComponent from '../components/LandingScreen/IC-Logo';
import { Env } from '../config/env';
import { RootState } from '../redux/store';

import './Login.css';

const Login = () => {
  const theme = useSelector((state: RootState) => state.user.theme);
  const location = useLocation();
  const redirectTo = location.state?.from || window.location.origin;
  let loginUrl = '#'; // Default fallback URL
  try {
    loginUrl = new URL(
      `/v1/api/auth/login?${new URLSearchParams({
        redirect: 'true',
        success_redirect_url: new URL(redirectTo, window.location.origin).toString(),
      }).toString()}`,
      Env.API_BASE_URL,
    ).toString();
  } catch (error) {
    console.error('Error constructing login URL:', error);
  }
  return (
    <section className="loginPage">
      <div className="login-container">
        <div className="image-container">
          <img
            src={theme === 'light-theme' ? '/images/Login-Page-Light-Mode-IC.png' : '/images/login-image.png'}
            alt="Insights Copilot"
            className="login-image"
          />
        </div>
        <div className="login-content">
          <div className="login-main-content">
            <div className="login-header">
              <p>Welcome to</p>
              <LogoComponent />
            </div>
            <div className="login-text">
              <p className="login-text-info">Your Decision Making Thought Partner</p>
              <p className="login-text-description">
                Start unraveling actionable insights instantly through the power of Generative AI!
              </p>
              <a href={loginUrl} className="login-button">
                Login{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <mask id="mask0_4459_1680" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                    <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_4459_1680)">
                    <path
                      d="M4.66666 10.5C5.22221 10.5 5.69443 10.3056 6.08332 9.91667C6.47221 9.52778 6.66666 9.05556 6.66666 8.5C6.66666 7.94444 6.47221 7.47222 6.08332 7.08333C5.69443 6.69444 5.22221 6.5 4.66666 6.5C4.1111 6.5 3.63888 6.69444 3.24999 7.08333C2.8611 7.47222 2.66666 7.94444 2.66666 8.5C2.66666 9.05556 2.8611 9.52778 3.24999 9.91667C3.63888 10.3056 4.1111 10.5 4.66666 10.5ZM4.66666 12.5C3.55555 12.5 2.6111 12.1111 1.83332 11.3333C1.05555 10.5556 0.666656 9.61111 0.666656 8.5C0.666656 7.38889 1.05555 6.44444 1.83332 5.66667C2.6111 4.88889 3.55555 4.5 4.66666 4.5C5.56666 4.5 6.35277 4.75556 7.02499 5.26667C7.69721 5.77778 8.16666 6.41111 8.43332 7.16667H14.0167L15.3333 8.48333L13 11.15L11.3333 9.83333L9.99999 11.1667L8.66666 9.83333H8.43332C8.15555 10.6333 7.67221 11.2778 6.98332 11.7667C6.29443 12.2556 5.52221 12.5 4.66666 12.5Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </svg>
              </a>
              <div className="line" style={{ maxWidth: '122px', marginTop: '41px' }}></div>
              <footer style={{ marginTop: '33px' }}>
                <p style={{ marginTop: '3px' }}>A product of</p>
                <img
                  src={theme === 'light-theme' ? '/images/ABI_Logo_Light_Theme.png' : '/images/AbinbevLogo.png'}
                  alt="ABInBev"
                  className="abinbev-logo"
                />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
