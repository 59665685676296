import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../../hooks/useAnalytics';
import { addChatToConversation } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';

import styles from './MessageEditableQuery.module.css';

export const MessageEditableQuery: React.FC<{
  queryNodeId: string;
}> = ({ queryNodeId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const analytics = useAnalytics();

  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const queryNode = conversationTree?.nodes[queryNodeId];
  const [query, setQuery] = useState(queryNode?.data.query ?? '');
  const [isEditing, setIsEditing] = useState(false);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const isSemanticCacheEnabled = useSelector((state: RootState) => state.conversation.isSemanticCacheEnabled);
  const isCacheEnabled = useSelector((state: RootState) => state.conversation.isCacheEnabled);
  const isPro = useSelector((state: RootState) => state.user.isPro);
  const currentProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const receipient = useSelector((state: RootState) => state.conversation.isReceipient);
  const userEmail = useSelector((state: RootState) => state.user.userData?.email)?.toLowerCase();
  const onEditSubmit = async () => {
    setIsEditing(false);
    if (conversationTree && queryNode && query.trim() !== '') {
      await dispatch(
        addChatToConversation({
          conversationId: conversationTree.conversation_id,
          body: {
            parent_node_id: queryNode.parent === conversationTree.conversation_id ? null : queryNode.parent,
            query: query,
            is_cached: isCacheEnabled,
            is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
            is_pro: isPro,
          },
        }),
      );

      analytics.trackChatSubmitEvent({
        mode: 'edit',
        is_cached: isCacheEnabled,
        is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
        conversation_id: conversationTree.conversation_id || '',
        project_key: currentProjectKey,
        is_pro: isPro,
      });
    }
  };

  const onCancel = () => {
    setQuery(conversationTree?.nodes[queryNodeId].data.query ?? '');
    setIsEditing(false);
  };

  useEffect(() => {
    if (isQuestionSubmitted) {
      // If question is being submitting, don't allow edits
      onCancel();
    }
  }, [isQuestionSubmitted]);

  if (!conversationTree) {
    return null;
  }

  return isEditing ? (
    <div className={styles.editableQueryContainer}>
      <textarea
        className={styles.textarea}
        placeholder="Edit your question here"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        ref={inputRef}
      />
      <button className={`${styles['icon-button']} ${styles['cancel-button']}`} onClick={onCancel}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            className="svg-icon-path"
            d="M15.8332 5.3415L14.6582 4.1665L9.99984 8.82484L5.3415 4.1665L4.1665 5.3415L8.82484 9.99984L4.1665 14.6582L5.3415 15.8332L9.99984 11.1748L14.6582 15.8332L15.8332 14.6582L11.1748 9.99984L15.8332 5.3415Z"
            fill="#CDCDCD"
          />
        </svg>
      </button>
      <button
        className={`${styles['icon-button']} ${styles['submit-button']}`}
        onClick={() => onEditSubmit()}
        disabled={isQuestionSubmitted}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <mask id="mask0_15795_9908" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
            <rect width="22" height="22" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_15795_9908)">
            <path
              className="svg-icon-path"
              d="M3.01758 17.8529V12.487L9.5361 10.6984L3.01758 8.9098V3.54395L18.4991 10.6984L3.01758 17.8529Z"
              fill="#818181"
              fillOpacity={query.trim() === '' ? 0.61 : 1}
            />
          </g>
        </svg>
      </button>
    </div>
  ) : (
    <div className={styles.readonlyQueryRoot}>
      <div className={styles.readonlyQueryContainer}>
        <p>{conversationTree.nodes[queryNodeId].data.query}</p>
      </div>
      {!receipient && conversationTree.nodes[queryNodeId].data.user_email.toLowerCase() === userEmail && (
        <button
          className={styles['icon-button']}
          onClick={() => {
            setIsEditing(true);
            setTimeout(() => {
              inputRef.current?.focus();
            }, 500);
          }}
          disabled={isQuestionSubmitted}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              className="svg-icon-path"
              d="M2.49902 17.501H5.62402L14.8407 8.28432L11.7157 5.15932L2.49902 14.376V17.501ZM4.16569 15.0677L11.7157 7.51765L12.4824 8.28432L4.93236 15.8343H4.16569V15.0677Z"
              fill="#CDCDCD"
              fillOpacity={query.trim() === '' ? 0.61 : 1}
            />
            <path
              className="svg-icon-path"
              d="M15.3074 2.74265C14.9824 2.41765 14.4574 2.41765 14.1324 2.74265L12.6074 4.26765L15.7324 7.39265L17.2574 5.86765C17.5824 5.54265 17.5824 5.01765 17.2574 4.69265L15.3074 2.74265Z"
              fill="#CDCDCD"
              fillOpacity={query.trim() === '' ? 0.61 : 1}
            />
          </svg>
        </button>
      )}
    </div>
  );
};
