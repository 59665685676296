import { getCookie } from 'typescript-cookie';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { options } from '../../components/Core/SelectMenu/selectMenu';
import { setAuthorizationToken } from '../../services/apiService/globals';

export type ProjectKey = {
  id: string;
  project_name: string;
  display_name: string;
};

export type UserData = {
  username: string;
  project_keys: ProjectKey[];
  currentProjectKey: string;
  email: string;
  image: string;
};

type Option = {
  icon: {
    type?: string;
    fill?: string;
    viewBox?: string;
    maskId?: string;
    pathData?: string;
  };
  text: string;
};

export type UserState = {
  isLoggedIn: boolean;
  userData: UserData | null;
  isSettingsOpen: boolean;
  isProfileOpen: boolean;
  isMenuOpen: boolean;
  isPro: boolean;
  theme: string;
  selectedOption: Option | null; // Add this line
  registered: boolean;
  sharedProjectKey: string;
};

const accessToken = getCookie('access_token') || localStorage.getItem('access_token');
const isLoggedIn = !!getCookie('is_logged_in') || !!accessToken;

if (accessToken) {
  setAuthorizationToken(accessToken);
}

const initialState: UserState = {
  isLoggedIn,
  userData: null,
  isSettingsOpen: false,
  isProfileOpen: false,
  isPro: true,
  isMenuOpen: false,
  theme: 'dark-theme',
  selectedOption: {
    icon: options[0].icon,
    text: options[0].text,
  },
  registered: true,
  sharedProjectKey: '',
};

const theme = localStorage.getItem('theme');

if (theme) {
  initialState.theme = theme;
}

if (theme === 'light-theme') {
  initialState.selectedOption = {
    icon: options[1].icon,
    text: options[1].text,
  };
} else {
  initialState.selectedOption = {
    icon: options[0].icon,
    text: options[0].text,
  };
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<UserState['isLoggedIn']>) => {
      state.isLoggedIn = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserState['userData']>) => {
      state.userData = action.payload;
    },
    clearUserData: (state) => {
      state.userData = null;
    },
    setIsSettingsOpen: (state, action: PayloadAction<UserState['isSettingsOpen']>) => {
      state.isSettingsOpen = action.payload;
    },
    setRegistered: (state, action: PayloadAction<UserState['registered']>) => {
      state.registered = action.payload;
    },
    setSharedProjectKey: (state, action: PayloadAction<UserState['sharedProjectKey']>) => {
      state.sharedProjectKey = action.payload;
    },
    setIsProfileOpen: (state, action: PayloadAction<UserState['isProfileOpen']>) => {
      state.isProfileOpen = action.payload;
    },
    setIsMenuOpen: (state, action: PayloadAction<UserState['isMenuOpen']>) => {
      state.isMenuOpen = action.payload;
    },
    setUserProjectKeys: (state, action: PayloadAction<NonNullable<UserState['userData']>['project_keys']>) => {
      if (state.userData) {
        state.userData.project_keys = action.payload;
      }
    },
    setCurrentUserProjectKey: (
      state,
      action: PayloadAction<NonNullable<UserState['userData']>['currentProjectKey']>,
    ) => {
      if (state.userData) {
        state.userData.currentProjectKey = action.payload;
      }
    },
    setIsPro: (state, action: PayloadAction<UserState['isPro']>) => {
      state.isPro = action.payload;
    },
    setTheme: (state, action: PayloadAction<UserState['theme']>) => {
      state.theme = action.payload;
    },
    setSelectedOption(state, action: PayloadAction<UserState['selectedOption']>) {
      // Add this reducer
      state.selectedOption = action.payload;
    },
  },
});

export const {
  setIsLoggedIn,
  setUserData,
  clearUserData,
  setIsSettingsOpen,
  setIsProfileOpen,
  setIsPro,
  setUserProjectKeys,
  setCurrentUserProjectKey,
  setTheme,
  setIsMenuOpen,
  setSelectedOption,
  setRegistered,
  setSharedProjectKey,
} = userSlice.actions;
export default userSlice.reducer;
