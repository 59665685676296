import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { raiseRequest } from '../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../redux/store';
import LogoComponent from '../LandingScreen/IC-Logo';

import EmailSuccessPopup from './EmailSuccessPopup';

import './Unregistered.css';
const Unregistered = () => {
  const sharedProjectKey = useSelector((state: RootState) => state.user.sharedProjectKey);
  const [isRequestSent, setIsRequestSent] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const requestAccess = () => {
    dispatch(raiseRequest({ projectKey: sharedProjectKey })).then((result) => {
      if (result.type.endsWith('fulfilled')) {
        setIsRequestSent(true);
      }
    });
  };

  return (
    <div className="unregisteredContainer">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '23px 24px 20px 24px',
          gap: '20px',
          borderRadius: '8px',
          background: 'var(--Greys-Top-700, #272727)',
        }}
      >
        <div className="unregisteredLogo">
          <LogoComponent />
        </div>
        <div className="unregisteredText">
          <p style={{ fontSize: '14px', fontWeight: '600' }}>Explore the full potential of Insights Copilot</p>
          <p style={{ marginTop: '14px' }}>
            Insights Copilot is AB InBev&apos;s AI-powered assistant that helps you extract valuable, actionable
            information from various data sources in seconds, turning data-driven decisions faster and easier. Request
            access or book some time with us to know more about it.
          </p>
          <div className="access-buttons">
            <button className="request-access-button" onClick={requestAccess}>
              Request Access{' '}
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                <path
                  d="M9.42539 5.33654C7.7283 4.98077 6.40183 3.6543 6.04612 1.95718C6.00242 1.74885 5.81867 1.59961 5.60574 1.59961C5.39282 1.59961 5.20907 1.74885 5.1654 1.95721C4.80966 3.6543 3.48319 4.98075 1.7861 5.33646C1.57774 5.38013 1.42847 5.56391 1.42847 5.7768C1.42847 5.9897 1.57771 6.17348 1.7861 6.21715C3.48316 6.57292 4.80958 7.89936 5.16532 9.59645C5.20899 9.80481 5.39274 9.95408 5.60566 9.95408C5.81856 9.95408 6.00234 9.80484 6.04601 9.59645C6.40178 7.89936 7.72828 6.57292 9.42537 6.21723C9.63373 6.17356 9.783 5.98981 9.783 5.77689C9.78297 5.56399 9.63373 5.38021 9.42539 5.33654Z"
                  fill="#07080B"
                />
                <path
                  d="M12.6954 9.54944C11.8959 9.38185 11.2709 8.75688 11.1032 7.95724C11.0596 7.74888 10.8758 7.59961 10.6629 7.59961C10.45 7.59961 10.2662 7.74882 10.2226 7.95721C10.0549 8.75682 9.42992 9.38179 8.63033 9.54941C8.42197 9.59308 8.27271 9.77686 8.27271 9.98975C8.27271 10.2027 8.42195 10.3864 8.63033 10.4301C9.42989 10.5977 10.0549 11.2227 10.2225 12.0223C10.2662 12.2307 10.4499 12.38 10.6628 12.38C10.8757 12.38 11.0595 12.2307 11.1032 12.0223C11.2708 11.2227 11.8958 10.5977 12.6954 10.4301C12.9038 10.3865 13.0531 10.2027 13.0531 9.98978C13.0531 9.77688 12.9038 9.59313 12.6954 9.54944Z"
                  fill="#07080B"
                />
              </svg>
            </button>
            <button
              className="book-time-button"
              onClick={() =>
                window.open(
                  'https://outlook.office.com/bookwithme/user/3563ccc9185e4efd97c29ee846eba50d@AB-Inbev.com/meetingtype/XdGwqyukA0WUbh9Wf5djOQ2?anonymous&ep=mcard',
                  '_blank',
                )
              }
            >
              Schedule a Session
            </button>
          </div>
        </div>
      </div>
      {isRequestSent && <EmailSuccessPopup setIsRequestSent={setIsRequestSent} />}
    </div>
  );
};

export default Unregistered;
